export default function (Component) {
      Component.options.frontmatter = {
  "published": "2019-10-28T00:00:00.000Z",
  "tags": [
    "vue",
    "web-components",
    "rollup-plugin-vue"
  ],
  "title": "Shipping Web Components with Vue",
  "excerpt": "<p>I have been watching the web component spec. However, all the projects I have worked on were either using Vue or React, and these frameworks are more than capable of building complex applications. I never felt like using web components, but that changed yesterday.</p>\n"
}
    }